import { BufAny } from '../../../../bufbuild';
import { BlackJackPlayerSeatHand, BlackJackPlayerSeatState } from '../../../../client/rpc/games/MultiseatBlackJack';
import { IPlaySeatData } from '../../../../client/rpc/types/entitydata/game';
import { DebugBase } from '../../../../common';
import { IToJsOpts, toJs } from '../../../../helpers';

/**
 * Contains data and providers data accessors for a single seat within the multiseat blackjack table.
 *
 * Note: Does not extend DataStore since each instance is managed by the MSBJ play store. However, it
 * does conform otherwise such as isPopulated, isMobXBound, etc.
 */
class MultiseatBlackJackSeatStore extends DebugBase {
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	protected _data: Nullable<IPlaySeatData> = null;

	protected _isMobXBound: boolean = false;

	protected _lastUpdatedTs: number = 0;

	/* #endregion ---- Properties ------------------------------------------------------------------------------------ */

	/* #region ---- CONSTRUCTOR -------------------------------------------------------------------------------------- */

	constructor(seatData: Nullable<IPlaySeatData>) {
		super();

		this._data = seatData;
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------ */

	/**
	 * GET/SET. The underlying data inside this store.
	 */
	public get data(): Nullable<IPlaySeatData> {
		return this._data;
	}
	public set data(seatData: Nullable<IPlaySeatData>) {
		this._data = seatData;
	}

	/**
	 * @returns TRUE if this store is populated with data.
	 */
	public get isPopulated(): boolean {
		return this.data != null;
	}

	/**
	 * @returns The unix timestamp (UTC) for when the data in this store was last updated.
	 */
	public get lastUpdatedTs() {
		return this._lastUpdatedTs;
	}

	/**
	 * Gets/sets whether or not this class instance is currently bound to MobX as an observable.
	 */
	public get isMobXBound(): boolean {
		return this._isMobXBound;
	}
	public set isMobXBound(val: boolean) {
		this._isMobXBound = val;
	}

	/**
	 * @returns The list of player game states.
	 */
	public get playerGameStates(): BlackJackPlayerSeatState[] {
		const raw = this.data?.playerGameState;
		if (!raw) {
			return [];
		}

		const entries: BlackJackPlayerSeatState[] = [];

		raw.forEach((item: BufAny, index: number) => {
			try {
				const entry = BlackJackPlayerSeatState.fromBinary(item.value);
				entries.push(entry);
			} catch (e) {
				this.warn(`Unable to convert player game state entry ${index}`, 'playerGameState', {
					item,
					error: e,
				});
			}
		});

		return entries;
	}

	public get seatId(): string {
		return this.data?.seatId || '';
	}

	public get spectatorId(): string {
		return this.data?.spectatorId || '';
	}

	public get seatNum(): number {
		return Number(this.data?.seatNum) || -1;
	}

	public get playerId(): string {
		return this.data?.playerId || '';
	}

	public get displayName(): string {
		return this.data?.displayName || '';
	}

	public get imageUrl(): string {
		return this.data?.imageUrl || '';
	}

	/**
	 * @returns A flat list of player hands for this seat.
	 */
	public get playerHands(): BlackJackPlayerSeatHand[] {
		const hands: BlackJackPlayerSeatHand[] = [];

		this.playerGameStates.forEach((state: BlackJackPlayerSeatState) => {
			if (state.hands.length > 0) {
				hands.push(state.hands[0]);
			}
		});

		return hands;
	}

	/**
	 * @returns A JSON export of the current pertinent data.
	 */
	public override toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const toJs = (val: unknown, opts?: Maybe<IToJsOpts>) => this.toJs(val, extended, { ...opts });

		const result: PlainObject = {
			data: toJs(this.data),
			isPopulated: this.isPopulated,
			seatId: this.seatId,
			spectatorId: this.spectatorId,
			seatNum: this.seatNum,
			playerId: this.playerId,
			displayName: this.displayName,
			imageUrl: this.imageUrl,
			playerHands: toJs(this.playerHands),
			lastUpdatedTs: this.lastUpdatedTs,
		};

		if (extended) {
			result.extended = {
				playerGameStates: toJs(this.playerGameStates),
			};
		}

		return result;
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/**
	 * Converts the given value to a JS object using the value of `this.isMobXBound`.
	 */
	protected toJs(val: unknown, extended?: Maybe<boolean>, opts?: Maybe<IToJsOpts>): unknown {
		return toJs(val, { ...opts, extended, useMobXToJs: this.isMobXBound });
	}

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the base class method.
	 *
	 * @returns The label to use when debugging.
	 */
	protected get debugClassLabel(): string {
		return MultiseatBlackJackSeatStore.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Store.MultiseatBlackJackSeatStore`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { MultiseatBlackJackSeatStore as default };
export { MultiseatBlackJackSeatStore };
