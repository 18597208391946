import { GameKey } from 'Constants';
import { IClientRpcSdkConfig, IMultiseatBlackJackRpcSdk, newMultiseatBlackJackSdk } from 'server/lib-rpc';
import { Config } from 'helpers/config';

// Instance of the SDK class used by clients
let _sdk: IMultiseatBlackJackRpcSdk;

const setupSdk = (opts?: Maybe<Partial<IClientRpcSdkConfig>>): IMultiseatBlackJackRpcSdk => {
	if (_sdk) {
		return _sdk;
	}

	const isDebugEnabled = opts?.isDebugEnabled;
	const config: IClientRpcSdkConfig = { ...opts, appConfig: Config, gameKey: GameKey };

	_sdk = newMultiseatBlackJackSdk(config);

	if (isDebugEnabled === true) {
		const sds = window.sds ?? {};
		sds.sdk = _sdk;
	}

	return _sdk;
};

/**
 * @returns The SDK instance
 */
const getSdk = (): IMultiseatBlackJackRpcSdk => _sdk;

export { setupSdk, getSdk, _sdk as sdk };
