import { AnnotationsMap, computed, makeObservable, observable } from 'mobx';
import { PlayStoreMobXAnnotations } from '../../../../store/PlayStore';
import { MultiseatBlackJackSeatStore } from './MultiseatBlackJackSeatStore';
import { MultiseatBlackJackSeatStoreMobXPrivateProps } from './types.mobx';

/**
 * MobX annotations for `MultiseatBlackJackSeatStore`.
 */
const MultiseatBlackJackSeatStoreMobXAnnotations: AnnotationsMap<
	MultiseatBlackJackSeatStore,
	MultiseatBlackJackSeatStoreMobXPrivateProps
> = {
	// Inherit from base PlayStore
	...PlayStoreMobXAnnotations,

	// ---- Observed -----------------------------------------------------

	// >>> Properties
	_data: observable,
	_lastUpdatedTs: observable,

	// ---- Computed -----------------------------------------------------

	// >> Public

	data: computed,
	isPopulated: computed,
	seatId: computed,
	spectatorId: computed,
	seatNum: computed,
	playerId: computed,
	displayName: computed,
	imageUrl: computed,
	playerHands: computed,
	lastUpdatedTs: computed,

	// ---- Actions ------------------------------------------------------

	// ---- Excluded -----------------------------------------------------

	_isMobXBound: false,
};

/**
 * Makes the specified `MultiseatBlackJackSeatStore` instance an observable MobX object.
 */
const bindMultiseatBlackJackSeatStoreMobX = (instance: MultiseatBlackJackSeatStore): boolean => {
	if (instance.isMobXBound) {
		return false;
	}

	makeObservable<MultiseatBlackJackSeatStore, MultiseatBlackJackSeatStoreMobXPrivateProps>(instance, {
		...MultiseatBlackJackSeatStoreMobXAnnotations,
	});

	instance.isMobXBound = true;

	return true;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { MultiseatBlackJackSeatStoreMobXAnnotations, bindMultiseatBlackJackSeatStoreMobX };
