import { isObjectEmpty } from './object';

const generateRandomString = (len: number = 10, charSet: string = ''): string => {
	charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

	let str: string = '';
	for (let i: number = 0; i < len; i++) {
		const pos: number = Math.floor(Math.random() * charSet.length);
		str += charSet.substring(pos, pos + 1);
	}

	return str;
};

const pad = (num: number, opts?: Optional<{ maxLength?: number; fillString?: string }>): string => {
	const { maxLength = 2, fillString = '0' } = opts ?? {};

	return num.toString().padStart(maxLength, fillString);
};

const renderTemplate = (template: string, data: StringDict) => {
	if (template === '' || isObjectEmpty(data)) {
		return template;
	}

	return template.replace(/\{\{(.*?)\}\}/g, (item: string) => {
		const propName = item.substring(2, item.length - 2);
		if (data[propName]) {
			return data[propName];
		} else {
			return `${item}`;
		}
	});
};

export { generateRandomString, pad, renderTemplate };
