// @generated by protoc-gen-es v1.3.0 with parameter "target=js+dts"
// @generated from file blackjack_multiseat.proto (package com.sands.ldx.games, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Card } from "./games_common_pb.js";

/**
 * @generated from message com.sands.ldx.games.BlackjackMultiSeatGameState
 */
export const BlackjackMultiSeatGameState = proto3.makeMessageType(
  "com.sands.ldx.games.BlackjackMultiSeatGameState",
  () => [
    { no: 1, name: "phase", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "dealer_hand", kind: "message", T: BlackjackMultiSeatHand },
  ],
);

/**
 * @generated from message com.sands.ldx.games.BlackjackMultiSeatGameStateDealer
 */
export const BlackjackMultiSeatGameStateDealer = proto3.makeMessageType(
  "com.sands.ldx.games.BlackjackMultiSeatGameStateDealer",
  () => [
    { no: 1, name: "phase", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "hand", kind: "message", T: BlackjackMultiSeatHandDealer },
    { no: 3, name: "seats", kind: "message", T: BlackjackMultiSeatSeatStateDealer, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.games.BlackjackMultiSeatSeatState
 */
export const BlackjackMultiSeatSeatState = proto3.makeMessageType(
  "com.sands.ldx.games.BlackjackMultiSeatSeatState",
  () => [
    { no: 1, name: "hands", kind: "message", T: BlackjackMultiSeatHand, repeated: true },
    { no: 2, name: "active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message com.sands.ldx.games.BlackjackMultiSeatSeatStateDealer
 */
export const BlackjackMultiSeatSeatStateDealer = proto3.makeMessageType(
  "com.sands.ldx.games.BlackjackMultiSeatSeatStateDealer",
  () => [
    { no: 1, name: "hands", kind: "message", T: BlackjackMultiSeatHandDealer, repeated: true },
    { no: 2, name: "active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "needs_card", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "seat_position", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message com.sands.ldx.games.BlackjackMultiSeatPlayerState
 */
export const BlackjackMultiSeatPlayerState = proto3.makeMessageType(
  "com.sands.ldx.games.BlackjackMultiSeatPlayerState",
  () => [
    { no: 1, name: "seats", kind: "message", T: BlackjackMultiSeatSeatState, repeated: true },
  ],
);

/**
 * @generated from message com.sands.ldx.games.BlackjackMultiSeatHand
 */
export const BlackjackMultiSeatHand = proto3.makeMessageType(
  "com.sands.ldx.games.BlackjackMultiSeatHand",
  () => [
    { no: 1, name: "hand_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "cards", kind: "message", T: Card, repeated: true },
    { no: 3, name: "scores", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 4, name: "score", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "blackjack", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "bust", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "hand_state", kind: "enum", T: proto3.getEnumType(BlackjackMultiSeatHand_HandStatus) },
    { no: 8, name: "outcome", kind: "enum", T: proto3.getEnumType(BlackjackMultiSeatHand_Outcome) },
    { no: 9, name: "is_active_hand", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "charlie", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from enum com.sands.ldx.games.BlackjackMultiSeatHand.Outcome
 */
export const BlackjackMultiSeatHand_Outcome = proto3.makeEnum(
  "com.sands.ldx.games.BlackjackMultiSeatHand.Outcome",
  [
    {no: 0, name: "OUTCOME_UNDEFINED", localName: "UNDEFINED"},
    {no: 1, name: "OUTCOME_WON", localName: "WON"},
    {no: 2, name: "OUTCOME_LOSS", localName: "LOSS"},
    {no: 3, name: "OUTCOME_TIE", localName: "TIE"},
  ],
);

/**
 * @generated from enum com.sands.ldx.games.BlackjackMultiSeatHand.HandStatus
 */
export const BlackjackMultiSeatHand_HandStatus = proto3.makeEnum(
  "com.sands.ldx.games.BlackjackMultiSeatHand.HandStatus",
  [
    {no: 0, name: "STATUS_UNDEFINED"},
    {no: 1, name: "STATUS_STOOD"},
    {no: 2, name: "STATUS_SURRENDERED"},
    {no: 3, name: "STATUS_DOUBLED"},
  ],
);

/**
 * @generated from message com.sands.ldx.games.BlackjackMultiSeatHandDealer
 */
export const BlackjackMultiSeatHandDealer = proto3.makeMessageType(
  "com.sands.ldx.games.BlackjackMultiSeatHandDealer",
  () => [
    { no: 1, name: "hand_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "cards", kind: "message", T: Card, repeated: true },
    { no: 3, name: "scores", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 4, name: "score", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "blackjack", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "bust", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "hand_state", kind: "enum", T: proto3.getEnumType(BlackjackMultiSeatHandDealer_HandStatus) },
    { no: 8, name: "outcome", kind: "enum", T: proto3.getEnumType(BlackjackMultiSeatHandDealer_Outcome) },
    { no: 9, name: "needs_card", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from enum com.sands.ldx.games.BlackjackMultiSeatHandDealer.Outcome
 */
export const BlackjackMultiSeatHandDealer_Outcome = proto3.makeEnum(
  "com.sands.ldx.games.BlackjackMultiSeatHandDealer.Outcome",
  [
    {no: 0, name: "OUTCOME_UNDEFINED", localName: "UNDEFINED"},
    {no: 1, name: "OUTCOME_WON", localName: "WON"},
    {no: 2, name: "OUTCOME_LOSS", localName: "LOSS"},
    {no: 3, name: "OUTCOME_TIE", localName: "TIE"},
  ],
);

/**
 * @generated from enum com.sands.ldx.games.BlackjackMultiSeatHandDealer.HandStatus
 */
export const BlackjackMultiSeatHandDealer_HandStatus = proto3.makeEnum(
  "com.sands.ldx.games.BlackjackMultiSeatHandDealer.HandStatus",
  [
    {no: 0, name: "STATUS_UNDEFINED"},
    {no: 1, name: "STATUS_STOOD"},
  ],
);

