import { AnnotationsMap, computed, makeObservable } from 'mobx';
import { PlayStoreMobXAnnotations } from '../../../../store/PlayStore';
import { MultiseatBlackJackPlayStore } from './MultiseatBlackJackPlayStore';
import { MultiseatBlackJackPlayStoreMobXPrivateProps } from './types.mobx';

/**
 * MobX annotations for `MultiseatBlackJackPlayStore`.
 */
const MultiseatBlackJackPlayStoreMobXAnnotations: AnnotationsMap<
	MultiseatBlackJackPlayStore,
	MultiseatBlackJackPlayStoreMobXPrivateProps
> = {
	// Inherit from base PlayStore
	...PlayStoreMobXAnnotations,

	// ---- Computed -----------------------------------------------------

	// >> Public

	gameState: computed,
	phase: computed,
	dealerHand: computed,
	seatCount: computed,
	playerHands: computed,
	allSeatActiveWagers: computed,
	otherPlayerActiveWagers: computed,

	// ---- Actions ------------------------------------------------------
	// NOTHING YET

	// ---- Excluded -----------------------------------------------------
	// NOTHING YET
};

/**
 * Makes the specified `MultiseatBlackJackPlayStore` instance an observable MobX object.
 */
const bindMultiseatBlackJackPlayStoreMobX = (instance: MultiseatBlackJackPlayStore): boolean => {
	if (instance.isMobXBound) {
		return false;
	}

	makeObservable<MultiseatBlackJackPlayStore, MultiseatBlackJackPlayStoreMobXPrivateProps>(instance, {
		...MultiseatBlackJackPlayStoreMobXAnnotations,
	});

	instance.isMobXBound = true;

	return true;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { MultiseatBlackJackPlayStoreMobXAnnotations, bindMultiseatBlackJackPlayStoreMobX };
