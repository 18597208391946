import { bufCreateRegistry } from '../../../../bufbuild';
import {
	BlackJackGameState,
	BlackJackPlayerSeatHand,
	BlackJackPlayerSeatState,
	IBlackJackGameStateData,
	IBlackJackPlayerSeatHandData,
	IBlackJackPlayerSeatStateData,
} from '../../../../client/rpc/games/MultiseatBlackJack';
import { IActiveWagerData, IPlaySeatDecisionStateData } from '../../../../client/rpc/types';
import { IGameService } from '../../../../client/service/types';
import { ICombinedSeatDataExt, IToJsOpts } from '../../../../helpers';
import { IPlayStoreOpts, PlayStore } from '../../../PlayStore';
import { IMultiseatBlackJackPlayerHandEntry, IMultiseatBlackJackPlayerSeatActiveWagersEntry } from './types.main';

class MultiseatBlackJackPlayStore extends PlayStore {
	/* #region ---- CONSTRUCTOR -------------------------------------------------------------------------------------- */

	constructor(service?: Maybe<IGameService>, opts?: Maybe<IPlayStoreOpts>) {
		super(service, opts);

		this._gameTypeRegistry = bufCreateRegistry(BlackJackGameState, BlackJackPlayerSeatState, BlackJackPlayerSeatHand);
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------ */

	/**
	 * @returns Game specific state data, or NULL if not available.
	 */
	public get gameState(): Nullable<IBlackJackGameStateData> {
		const raw = this.data?.gameState ?? null;
		if (raw == null) {
			return null;
		}

		return BlackJackGameState.fromBinary(raw.value) as IBlackJackGameStateData;
	}

	/**
	 * @returns The game state phase.
	 */
	public get phase(): string {
		return this.gameState?.phase ?? '';
	}

	/**
	 * @returns The current dealer hand.
	 */
	public get dealerHand(): Nullable<BlackJackPlayerSeatHand> {
		return this.gameState?.dealerHand ?? null;
	}

	/**
	 * @returns The fully resolved seats list. Includes data from both the table and play seat data.
	 */
	public override get seats(): ICombinedSeatDataExt<IBlackJackPlayerSeatStateData>[] {
		return this.resolveSeats<IBlackJackPlayerSeatStateData>({ typeRegistry: this._gameTypeRegistry });
	}

	/**
	 * @returns The player hands list.
	 */
	public get playerHands(): IMultiseatBlackJackPlayerHandEntry[] {
		const result: IMultiseatBlackJackPlayerHandEntry[] = [];

		const activePlayerId = this._externalDataDeps.activePlayerId;

		this.seats.map((seat) => {
			const playerId: string = seat.playerId;
			const isMySeat: boolean = activePlayerId !== '' && playerId === activePlayerId;
			const seatNumber: number = seat.seatNumber;
			const seatId: string = seat.seatId;
			const gameState: IBlackJackPlayerSeatStateData = seat.playerGameState[0];
			const hands = gameState?.hands ?? [];
			let handNumber: number = 1;

			hands.forEach((handData: IBlackJackPlayerSeatHandData) => {
				const isActive: boolean = handData.isActiveHand ?? false;

				result.push({ playerId, seatNumber, seatId, handNumber, handData, isActive, isMySeat });

				handNumber++;
			});
		});

		return result;
	}

	/**
	 * @returns A list of active wagers for every seat.
	 */
	public get allSeatActiveWagers(): IMultiseatBlackJackPlayerSeatActiveWagersEntry[] {
		const result: IMultiseatBlackJackPlayerSeatActiveWagersEntry[] = [];

		const activePlayerId = this._externalDataDeps.activePlayerId;

		this.seats.map((seat) => {
			const playerId: string = seat.playerId;
			const isMySeat: boolean = activePlayerId !== '' && playerId === activePlayerId;
			const seatNumber: number = seat.seatNumber;
			const seatId: string = seat.seatId;
			const decisionState: Nullable<IPlaySeatDecisionStateData> = seat.decisionState;
			const activeWagers = (decisionState?.activeWagers ?? []) as IActiveWagerData[];

			result.push({ playerId, seatNumber, seatId, isMySeat, activeWagers });
		});

		return result;
	}

	/**
	 * @returns A list of active wagers for every seat that is not the active player's seat.
	 */
	public get otherPlayerActiveWagers(): IMultiseatBlackJackPlayerSeatActiveWagersEntry[] {
		return this.allSeatActiveWagers.filter((entry) => !entry.isMySeat);
	}

	/**
	 * Overrides the parent class method.
	 *
	 * @returns A JSON export of the current pertinent data.
	 */
	public override toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const toJs = (val: unknown, opts?: Maybe<IToJsOpts>) => this.toJs(val, extended, { ...opts });

		const result: PlainObject = {
			// Everything from `PlayStore`
			...super.toJson(extended),
			phase: this.phase,
			gameState: toJs(this.gameState),
			seats: toJs(this.seats),
			playerHands: toJs(this.playerHands),
			dealerHand: toJs(this.dealerHand),
			allSeatActiveWagers: toJs(this.allSeatActiveWagers),
			otherPlayerActiveWagers: toJs(this.otherPlayerActiveWagers),
		};

		if (extended) {
			const baseExtended = (result.extended as PlainObject) ?? {};

			result.extended = {
				...baseExtended,
			};
		}

		return result;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the base class method.
	 *
	 * @returns The label to use when debugging.
	 */
	protected get debugClassLabel(): string {
		return MultiseatBlackJackPlayStore.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Store.MultiseatBlackJackPlayStore`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { MultiseatBlackJackPlayStore as default };
export { MultiseatBlackJackPlayStore };
