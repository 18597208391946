import { ConfigAdapterLdx } from '@sandsb2b/sds-client-shared/dist/config';

enum ClientCustomConfigVars {
	ENABLE_CHAT = 'ENABLE_CHAT',
}

class ClientConfigAdapter extends ConfigAdapterLdx {
	// Whether or not to show the chat icon in the game UI.
	public get chatEnabled(): boolean {
		return this.getBoolVar(ClientCustomConfigVars.ENABLE_CHAT);
	}
}

const _instance = new ClientConfigAdapter();
const getConfig = (): ClientConfigAdapter => _instance;

// ---- Exports -------------------------------------------------------------------------------------------------------

export { _instance as default };
export { _instance as Config, ClientConfigAdapter };
export { getConfig };
