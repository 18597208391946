interface IDebugOpts {
	prefix?: Maybe<string>;
	method?: Maybe<string>;
}

const debugMsg = (msg: string, opts?: Maybe<IDebugOpts>): string => {
	if (msg === '') {
		return '';
	}

	const logPrefix = opts?.prefix || '';
	const logMethod = opts?.method || '';

	const prefix: string[] = [];
	prefix.push(logPrefix !== '' ? `[${logPrefix}]: ` : '');
	prefix.push(logMethod !== '' ? `${logMethod} - ` : '');

	return `${prefix.join('')}${msg}`;
};

const logError = (msg: string, opts?: Maybe<IDebugOpts>, ...args: unknown[]): void => {
	console.error(debugMsg(msg, opts), ...args);
};

const logWarn = (msg: string, opts?: Maybe<IDebugOpts>, ...args: unknown[]): void => {
	console.warn(debugMsg(msg, opts), ...args);
};

const logInfo = (msg: string, opts?: Maybe<IDebugOpts>, ...args: unknown[]): void => {
	console.log(debugMsg(msg, opts), ...args);
};

type LoggerMethod = (msg: string, method?: Maybe<string>, ...args: unknown[]) => void;

interface ILogger {
	silent: boolean;
	prefix: string;
	error: LoggerMethod;
	warn: LoggerMethod;
	info: LoggerMethod;
}

class Logger implements ILogger {
	protected logPrefix: string = 'Logger';
	protected _silent: boolean = false;

	constructor(logPrefix: string) {
		this.logPrefix = logPrefix;
	}

	public get prefix(): string {
		return this.logPrefix;
	}

	public error(msg: string, method?: Maybe<string>, ...args: unknown[]) {
		!this.silent && logError(msg, { method, prefix: this.logPrefix }, ...args);
	}

	public warn(msg: string, method?: Maybe<string>, ...args: unknown[]) {
		!this.silent && logWarn(msg, { method, prefix: this.logPrefix }, ...args);
	}

	public info(msg: string, method?: Maybe<string>, ...args: unknown[]) {
		!this.silent && logInfo(msg, { method, prefix: this.logPrefix }, ...args);
	}

	public makeMessage(msg: string, method?: Maybe<string>): string {
		return debugMsg(msg, { method, prefix: this.logPrefix });
	}

	public get silent(): boolean {
		return this._silent;
	}

	public set silent(val: boolean) {
		this._silent = val;
	}
}

const newNoOpLogger = (): ILogger => ({
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	error: (msg: string, method?: Maybe<string>, ...args: unknown[]) => {},
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	warn: (msg: string, method?: Maybe<string>, ...args: unknown[]) => {},
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	info: (msg: string, method?: Maybe<string>, ...args: unknown[]) => {},
	silent: true,
	prefix: 'NoOpLogger',
});

export { debugMsg, logError, logWarn, logInfo, Logger, newNoOpLogger };
export type { ILogger };
