import omit from 'lodash/omit';

// Specify the game key such as 'blackjack', 'roulette', 'baccarat', etc.
const GameKey = 'blackjack_multiseat';

// Specify the game variant such as 'standard'.
const GameVariant = 'multiseat';

// Specify the game name such as 'Blackjack', 'Roulette', 'Baccarat', etc.
const GameName = 'Multiseat Blackjack';

// States used in the game logic.
enum GameState {
	OPEN = 'open',
	ACTIVE = 'active',
	INITIAL = 'initial',
	WAITING_ON_WAGER = 'waiting_on_wager',
	DEALING = 'dealing',
	PLAYER_CHOICE = 'choice_play_period',
	RESOLVED = 'resolved',
	COMPLETED = 'completed',
	FINAL = 'final',
}

// Available actions the player can take.
const ChoiceActions: Dict<string> = {
	FOLD: 'choice_fold',
	PLAY: 'choice_play',
};

// Action button labels
const ChoiceActionLabels: StringDict = {
	[ChoiceActions.EXAMPLE]: 'Example',
};

// Wagers map - currently known bets.
const Wagers: Dict<string> = {
	ANTE_WAGER: 'wager_ante',
	PLAY_WAGER: 'wager_play',
	SIX_CARD_WAGER: 'wager_six_card',
	PAIR_OR_MORE_WAGER: 'wager_pair_or_more',
};

const SidebetWagers = omit(Wagers, ['ANTE_WAGER']);

enum ResolutionType {
	NOT_SET = '',
	RESOLUTION = 'resolution',
	PAYOUT = 'payout',
	PUSH = 'push',
}

const UserType: Dict<string> = {
	PLAYER: 'player',
	DEALER: 'dealer',
};

const UiButtonType: Dict<string> = {
	MUTE: 'mute',
	MENU: 'menu',
	HELP: 'help',
	OPTIONS: 'options',
	TIP_DEALER: 'tip_dealer',
	GAME_HISTORY: 'game_history',
	DEPOSIT_MONEY: 'deposit_money',
};

const WinType: Dict<string> = {
	NONE: '',
	BASIC_WIN: 'basic_win',
	BIG_WIN: 'big_win',
	HUGE_WIN: 'huge_win',
	ULTIMATE_WIN: 'ultimate_win',
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export {
	GameKey,
	GameName,
	GameVariant,
	GameState,
	ChoiceActions,
	ChoiceActionLabels,
	Wagers,
	SidebetWagers,
	ResolutionType,
	UserType,
	UiButtonType,
	WinType,
};
