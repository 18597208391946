import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isPlainObject from 'lodash/isPlainObject';

const getObjectValue = <T extends object, U extends keyof T>(obj: T, key: U) => obj[key];
const entries = <T>(obj: { [s: string]: T } | ArrayLike<T>): [string, T][] => Object.entries(obj);
const keys = (obj: object): string[] => Object.keys(obj);

const isObjectEmpty = (obj?: Nullable<AnyObject>): boolean => {
	if (obj == null) {
		return true;
	}

	if (isPlainObject(obj) && isEmpty(obj)) {
		return true;
	}

	return isEmpty(obj);
};

/**
 * Shallow object comparison for the specified objects.
 */
const isObjectEqualShallow = (obj1: PlainObject, obj2: PlainObject): boolean => {
	// Quick same reference (ie. area of memory) check
	if (obj1 === obj2) {
		return true;
	}

	// Shallow compare all the props
	obj1 = obj1 || {};
	obj2 = obj2 || {};

	return (
		Object.keys(obj1).length === Object.keys(obj2).length && Object.keys(obj1).every((key) => obj1[key] === obj2[key])
	);
};

/**
 * Deep object comparison for the specified objects.
 */
const isObjectEqual = (obj1: PlainObject, obj2: PlainObject): boolean => {
	// Quick same reference (ie. area of memory) check
	if (obj1 === obj2) {
		return true;
	}

	// Deep compare via lodash.isEqual
	return isEqual(obj1, obj2);
};

/**
 * Deep array comparison for the specified arrays.
 */
const isArrayEqual = (arr1: Array<unknown>, arr2: Array<unknown>): boolean => {
	// Quick same reference (ie. area of memory) check
	if (arr1 === arr2) {
		return true;
	}

	// Quick length check
	if (arr1.length !== arr2.length) {
		return false;
	}

	// Deep compare via lodash.isEqual
	return isEqual(arr1, arr2);
};

/**
 * Deep array comparison for the specified maps.
 */
const isMapEqual = (map1: Map<string, unknown>, map2: Map<string, unknown>): boolean => {
	// Quick same reference (ie. area of memory) check
	if (map1 === map2) {
		return true;
	}

	// Map type check
	if (!(map1 instanceof Map) || !(map2 instanceof Map)) {
		return false;
	}

	// Quick length check
	if (map1.size !== map2.size) {
		return false;
	}

	// Deep compare via lodash.isEqual
	return isEqual(map1, map2);
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { getObjectValue, isObjectEmpty, entries, keys, isObjectEqualShallow, isObjectEqual, isArrayEqual, isMapEqual };
