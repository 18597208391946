import {
	bindMultiseatBlackJackPlayStoreMobX,
	MultiseatBlackJackPlayStore,
} from '../../../store/games/MultiseatBlackJack';
import { ClientRpcSdk, IClientRpcSdkConfig, SdkGameKey } from '../../ClientRpcSdk';
import { IMultiseatBlackJackRpcSdk } from './types';

/**
 * @returns A new game specific RPC SDK instance.
 */
const newMultiseatBlackJackSdk = (config: IClientRpcSdkConfig): IMultiseatBlackJackRpcSdk => {
	const sdkConfig = { ...config, gameKey: SdkGameKey.MSBJ };
	const sdk = new ClientRpcSdk<MultiseatBlackJackPlayStore>(sdkConfig);

	const cfg = sdkConfig.stores?.opts?.playStore;
	const service = sdk.services.getServices().gameService;

	const playStore =
		(cfg?.instance as MultiseatBlackJackPlayStore) ?? new MultiseatBlackJackPlayStore(service, cfg?.opts);

	bindMultiseatBlackJackPlayStoreMobX(playStore);
	sdk.stores.setPlayStore(playStore);

	return sdk;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { newMultiseatBlackJackSdk };
